import { ReactNode } from "react";
import { Stack } from "@mui/material";
// import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import { setFullScreen } from "utils/set-screen";

import Header from "layouts/header";

import MobileDialogView from "sections/mobile";
import AuthDialogView from "sections/auth";
import RuleDialogView from "sections/auth/rules";

import Background from "assets/images/background.gif";

const Layout = ({ children }: { children: ReactNode }) => {

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundImage: `url(${Background})`,
        backgroundSize: "100% 100%",
        touchAction: "pan-x"
      }}
      onClick={setFullScreen}
    >
      <Header />

      {children}

      <MobileDialogView />

      <AuthDialogView />
      <RuleDialogView />

      {/* <AddToHomeScreen /> */}
    </Stack>
  );
};

export default Layout;
