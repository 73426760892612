// routes
import { paths } from 'routes/paths';

// API
// ----------------------------------------------------------------------

export const API_URL = process.env.REACT_APP_API_URL;
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL;

// export const PATH_LOGIN = paths.auth.login; // as '/login'
export const DASHBOARD_PATH = paths.dashboard.root; // as '/home'

export const API_PATH = {
    // auth
    AUTH_ROOT: 'auth',
    AUTH_REGISTER: 'auth/user-register',
    AUTH_LOGIN: 'auth/user-login',
    AUTH_LOGOUT: 'auth/user-logout',
    AUTH_CHECK: 'auth/check-auth',
    // game
    GAME_GET_LIST: 'game/get-game-list',
    GAME_ACCESS_URL: 'game/get-access-url',
    GAME_SET_FAVORITE: 'game/set-game-favorite',
    // game
    USER_GET_DATA: 'user/get-user-data',
    USER_BET_HISTORY: 'user/get-bet-history',
    USER_PASSWORD_CHANGE: 'user/change-password',
    USER_TRANSACTION_HISTORY: 'user/get-transaction-history',
};

export const SOCKET_PATH = {
    SIGNOUT: 'auth:signout',
    ALERT: 'alert',
    CHAT: 'chat:message',
    COINFLIP_CREATE: 'coinflip:create',
    COINFLIP_JOIN: 'coinflip:join',
};
